import { Tooltip } from "@mui/material";
import useSettings from "src/hooks/useSettings";

import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/other/Iconify";

export default function SettingsButton() {
	const { open, setOpen } = useSettings();

	return (
		<Tooltip arrow title="Instellingen">
			<IconButtonAnimate id="fade-button" onClick={() => setOpen(!open)}>
				<Iconify icon="la:cog" />
			</IconButtonAnimate>
		</Tooltip>
	);
}
