/* eslint-disable @typescript-eslint/no-unsafe-member-access -- Clerk membership prop typing is unknown record */
import { useOrganization, useUser } from "@clerk/nextjs";
import { Avatar, Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: theme.palette.grey[500_12],
	transition: theme.transitions.create("opacity", {
		duration: theme.transitions.duration.shorter,
	}),
}));

type Props = {
	isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
	const { user } = useUser();
	const { membership } = useOrganization();

	const getRoleText = (role: string | null | undefined) => {
		switch (role) {
			case "administrator": {
				return "Beheerder";
			}
			case "manager": {
				return "Manager";
			}
			case "employee": {
				return "Medewerker";
			}
			case null: {
				return "Geen rol";
			}
			case undefined: {
				return "Onbekende rol";
			}
			default: {
				return "Geen rol";
			}
		}
	};

	return (
		<Link underline="none" color="inherit">
			<RootStyle
				sx={{
					...(isCollapse && {
						bgcolor: "transparent",
					}),
				}}
			>
				<Avatar src={user?.profileImageUrl} alt={user?.fullName ?? ""} />

				<Box
					sx={{
						ml: 2,
						transition: (theme) =>
							theme.transitions.create("width", {
								duration: theme.transitions.duration.shorter,
							}),
						...(isCollapse && {
							ml: 0,
							width: 0,
						}),
					}}
				>
					<Typography variant="subtitle2" noWrap>
						{user?.fullName}
					</Typography>
					<Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
						{/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- Any casting is necessary to prevent unknown typing */}
						{getRoleText((membership?.publicMetadata?.hr as any)?.role)}
					</Typography>
				</Box>
			</RootStyle>
		</Link>
	);
}
