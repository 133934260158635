import { Box, List, Stack, Tooltip } from "@mui/material";
import { SxProps, Theme, alpha, useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import { collapseIcon } from "src/layouts/dashboard/navbar/CollapseButton";

import { getActive } from "..";
import { NavConfig, NavSectionProps } from "../type";

import NavList from "./NavList";
import { ListSubheaderStyle } from "./style";

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
	const theme = useTheme();
	const [open, setOpen] = useState(() => {
		const json: Record<string, boolean> = {};
		for (const [index, group] of navConfig.entries()) {
			json[group.subheader] = index === 0;
		}
		return json;
	});
	const { pathname, asPath } = useRouter();

	// Always open the active group on load
	useEffect(() => {
		for (const group of navConfig) {
			for (const item of group.items) {
				const active = getActive(item.path, pathname, asPath);
				if (active) {
					setOpen((previous: any) => ({
						...previous,
						[group.subheader]: true,
					}));
				}
			}
		}
	}, [navConfig, pathname]);

	const groupHasActive = useCallback(
		(group: NavConfig) => group.items.find((item) => getActive(item.path, pathname, asPath)) !== undefined,
		[pathname, asPath]
	);

	const handleClick = (group: NavConfig) => {
		setOpen((previous: any) => ({
			...previous,
			[group.subheader]: !previous[group.subheader],
		}));
	};
	return (
		<Box {...other}>
			{navConfig.map((group) => (
				<List key={group.subheader} disablePadding sx={{ px: 2 }}>
					<ListSubheaderStyle
						disableGutters
						onClick={() => {
							if (!groupHasActive(group)) {
								handleClick(group);
							}
						}}
						sx={{
							display: "flex",
							gap: 2,
							cursor: groupHasActive(group) ? "cursor" : "pointer",
							alignItems: "center",
							justifyContent: "space-between",
							...((open[group.subheader] && {
								color: groupHasActive(group) && theme.palette.primary.main,
								backgroundColor: isCollapse && alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
							}) as SxProps<Theme>),
						}}
					>
						<Tooltip
							title={isCollapse && !groupHasActive(group) ? `${open[group.subheader] ? "Hide" : "Show"}` : ""}
							arrow
							placement="right"
						>
							<Stack gap={2} alignItems="center" direction="row">
								{group.subheader_icon && (
									<Box component="img" alt="subheader icon" sx={{ width: 25, height: 25 }} src={group.subheader_icon} />
								)}
								{!isCollapse && group.subheader}
							</Stack>
						</Tooltip>

						{!isCollapse && (
							<Box
								sx={{
									lineHeight: 0,
									transform: "rotate(90deg)",
									transition: (theme) =>
										theme.transitions.create("transform", {
											duration: theme.transitions.duration.shorter,
										}),
									...(open[group.subheader] && {
										transform: "rotate(270deg)",
									}),
								}}
							>
								{collapseIcon}
							</Box>
						)}
					</ListSubheaderStyle>
					{open[group.subheader] &&
						group.items.map((list) => (
							<NavList
								key={list.title + list.path}
								data={list}
								depth={1}
								hasChildren={!!list.children}
								isCollapse={isCollapse}
							/>
						))}
				</List>
			))}
		</Box>
	);
}
