import Iconify from "@components/other/Iconify";
import { NavConfig } from "@components/nav-section/type";
import { ROUTES } from "src/layouts/routes";

const ICONS = {
	dashboard: <Iconify icon="la:chart-pie" width={24} height={24} />,
	employees: <Iconify icon="la-users" width={24} height={24} />,
	general: <Iconify icon="bi:person-lines-fill" width={24} height={24} />,
	employment: <Iconify icon="clarity:contract-solid" width={24} height={24} />,
	overview: <Iconify icon="ooui:view-details-ltr" width={24} height={24} />,
	schedule: <Iconify icon="fa6-solid:calendar-day" width={24} height={24} />,
	contracts: <Iconify icon="mingcute:paper-fill" width={24} height={24} />,
	absence: <Iconify icon="mdi:bag-suitcase" width={24} height={24} />,
	sickness: <Iconify icon="ic:round-sick" width={24} height={24} />,
	competences: <Iconify icon="carbon:skill-level-intermediate" width={24} height={24} />,
	material: <Iconify icon="ep:goods-filled" width={24} height={24} />,
	documents: <Iconify icon="ic:round-perm-media" width={24} height={24} />,
	account: <Iconify icon="mdi:account-cog" width={24} height={24} />,
	workflow: <Iconify icon="la:file-alt" width={24} height={24} />,
	tasks: <Iconify icon="la:tasks" width={24} height={24} />,
	course: <Iconify icon="fluent-mdl2:education" width={24} height={24} />,
	calendar: <Iconify icon="la:calendar" width={24} height={24} />,
	declaration: <Iconify icon="material-symbols:upload-file-rounded" width={24} height={24} />,
};

export const navConfigEmployee: NavConfig[] = [
	{
		subheader: "Dashboard",
		subheader_icon: "/assets/icons/navbar/dashboard_subheader.svg",
		items: [
			{ title: "Overzicht", path: ROUTES.home.root, icon: ICONS.dashboard },
			{ title: "Takenlijst", path: ROUTES.tasks.root, icon: ICONS.tasks },
			{ title: "Kalender", path: ROUTES.calendar.root, icon: ICONS.calendar },
		],
	},
	{
		subheader: "Collega's",
		subheader_icon: "/assets/icons/navbar/invoice_subheader.svg",
		items: [
			{
				title: "Algemeen",
				path: ROUTES.user.profile.path(undefined, "algemeen"),
				icon: ICONS.general,
			},
			{
				title: "Dienstverband",
				path: ROUTES.user.profile.path(undefined, "dienstverband"),
				icon: ICONS.employment,
				children: [
					{
						title: "Overzicht",
						path: ROUTES.user.profile.path(undefined, "dienstverband", "overzicht"),
					},
					{
						title: "Contracten",
						path: ROUTES.user.profile.path(undefined, "dienstverband", "contracten"),
					},
				],
			},
			{
				title: "Verlof",
				path: ROUTES.user.profile.path(undefined, "verlof"),
				icon: ICONS.absence,
			},
			{
				title: "Verzuim",
				path: ROUTES.user.profile.path(undefined, "verzuim"),
				icon: ICONS.sickness,
			},
			{
				title: "Competenties",
				path: ROUTES.user.profile.path(undefined, "competenties"),
				icon: ICONS.competences,
			},
			{
				title: "Materiaal",
				path: ROUTES.user.profile.path(undefined, "materiaal"),
				icon: ICONS.material,
			},
			{
				title: "Documenten",
				path: ROUTES.user.profile.path(undefined, "documenten"),
				icon: ICONS.documents,
			},
			{
				title: "Declaraties",
				path: ROUTES.declaration.list,
				icon: ICONS.declaration,
			},
		],
	},
];

export const navConfigAdministrator: NavConfig[] = [
	{
		subheader: "Dashboard",
		subheader_icon: "/assets/icons/navbar/dashboard_subheader.svg",
		items: [
			{ title: "Overzicht", path: ROUTES.home.root, icon: ICONS.dashboard },
			{ title: "Takenlijst", path: ROUTES.tasks.root, icon: ICONS.tasks },
			{
				title: "Workflows",
				path: ROUTES.workflow.root,
				icon: ICONS.workflow,
			},
			{ title: "Kalender", path: ROUTES.calendar.root, icon: ICONS.calendar },
		],
	},
	{
		subheader: "Collega's",
		subheader_icon: "/assets/icons/navbar/invoice_subheader.svg",
		items: [
			{
				title: "Medewerkers",
				path: ROUTES.user.list.path,
				icon: ICONS.employees,
				children: [
					{
						title: "Lijst",
						path: ROUTES.user.list.path,
					},
					{
						title: "Profiel",
						path: ROUTES.user.profile.path(),
					},
					{
						title: "Nieuw",
						path: ROUTES.user.create.path, // Needs to be a function like Opus does when implementing user creation
					},
				],
			},
			{
				title: "Contracten",
				path: ROUTES.contract.list,
				icon: ICONS.contracts,
			},
			{
				title: "Declaraties",
				path: ROUTES.declaration.list,
				icon: ICONS.declaration,
			},
			{
				title: "Verlof",
				path: ROUTES.absence.list,
				icon: ICONS.absence,
			},
			{
				title: "Verzuim",
				path: ROUTES.sickness.list,
				icon: ICONS.sickness,
			},
			{
				title: "Competenties",
				path: ROUTES.competence.list,
				icon: ICONS.competences,
			},
		],
	},
	{
		subheader: "Bedrijfsmiddelen",
		subheader_icon: "/assets/icons/navbar/stock_subheader.svg",
		items: [
			{
				title: "Materiaal",
				path: ROUTES.item.root,
				icon: ICONS.material,
			},
		],
	},
	{
		subheader: "Cursussen",
		subheader_icon: "/assets/icons/navbar/planning_subheader.svg",
		items: [
			{
				title: "Opleidingen",
				path: ROUTES.course.root,
				icon: ICONS.course,
			},
		],
	},
];
