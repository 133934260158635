import {
	Avatar,
	Badge,
	Box,
	Button,
	Divider,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	ListSubheader,
	Tooltip,
	Typography,
} from "@mui/material";
import { noCase } from "change-case";
import Image from "next/image";
import { useState } from "react";

import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/other/Iconify";
import MenuPopover from "../../../components/other/MenuPopover";
import Scrollbar from "../../../components/other/Scrollbar";
import { fToNow } from "../../../utils/formatTime";

function renderContent(notification: NotificationItemProps) {
	const title = (
		<Typography variant="subtitle2">
			{notification.title}
			<Typography component="span" variant="body2" sx={{ color: "text.secondary" }}>
				&nbsp; {noCase(notification.description)}
			</Typography>
		</Typography>
	);

	if (notification.type === "order_placed") {
		return {
			avatar: (
				<Image
					alt={notification.title}
					src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
				/>
			),
			title,
		};
	}
	if (notification.type === "order_shipped") {
		return {
			avatar: (
				<Image
					alt={notification.title}
					src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
				/>
			),
			title,
		};
	}
	if (notification.type === "mail") {
		return {
			avatar: (
				<Image
					alt={notification.title}
					src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
				/>
			),
			title,
		};
	}
	if (notification.type === "chat_message") {
		return {
			avatar: (
				<Image
					alt={notification.title}
					src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
				/>
			),
			title,
		};
	}
	return {
		avatar: notification.avatar ? <Image alt={notification.title} src={notification.avatar} /> : null,
		title,
	};
}

function NotificationItem({ notification }: { notification: NotificationItemProps }) {
	const { avatar, title } = renderContent(notification);

	return (
		<ListItemButton
			sx={{
				py: 1.5,
				px: 2.5,
				mt: "1px",
				...(notification.isUnRead && {
					bgcolor: "action.selected",
				}),
			}}
		>
			<ListItemAvatar>
				<Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={title}
				secondary={
					<Typography
						variant="caption"
						sx={{
							mt: 0.5,
							display: "flex",
							alignItems: "center",
							color: "text.disabled",
						}}
					>
						<Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
						{fToNow(notification.createdAt)}
					</Typography>
				}
			/>
		</ListItemButton>
	);
}

export default function NotificationsPopover() {
	const [notifications, setNotifications] = useState<NotificationItemProps[]>([]);

	const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

	const [open, setOpen] = useState<HTMLElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const handleMarkAllAsRead = () => {
		setNotifications(
			notifications.map((notification) => ({
				...notification,
				isUnRead: false,
			}))
		);
	};

	return (
		<>
			<Tooltip arrow title="Notificaties">
				<IconButtonAnimate color={open ? "primary" : "default"} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
					<Badge badgeContent={totalUnRead} color="error">
						<Iconify icon="la:bell-solid" width={25} height={25} />
					</Badge>
				</IconButtonAnimate>
			</Tooltip>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
			>
				<Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle1">Notificaties</Typography>
						<Typography variant="body2" sx={{ color: "text.secondary" }}>
							Je hebt {totalUnRead} ongelezen berichten
						</Typography>
					</Box>

					{totalUnRead > 0 && (
						<Tooltip title=" Mark all as read">
							<IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
								<Iconify icon="eva:done-all-fill" width={20} height={20} />
							</IconButtonAnimate>
						</Tooltip>
					)}
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
					<List
						disablePadding
						subheader={
							<ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
								Nieuw
							</ListSubheader>
						}
					>
						{notifications.slice(0, 2).map((notification) => (
							<NotificationItem key={notification.id} notification={notification} />
						))}
					</List>

					<List
						disablePadding
						subheader={
							<ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "overline" }}>
								Recent
							</ListSubheader>
						}
					>
						{notifications.slice(2, 5).map((notification) => (
							<NotificationItem key={notification.id} notification={notification} />
						))}
					</List>
				</Scrollbar>

				<Divider sx={{ borderStyle: "dashed" }} />

				<Box sx={{ p: 1 }}>
					<Button disabled fullWidth disableRipple>
						Bekijk alles
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
}

type NotificationItemProps = {
	id: string;
	title: string;
	description: string;
	avatar: string | null;
	type: string;
	createdAt: Date;
	isUnRead: boolean;
};
