import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode, useState } from "react";

import { HEADER, NAVBAR } from "../../config";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import useResponsive from "../../hooks/useResponsive";
import useSettings from "../../hooks/useSettings";

import DashboardHeader from "./header";
import NavbarHorizontal from "./navbar/NavbarHorizontal";
import NavbarVertical from "./navbar/NavbarVertical";

type MainStyleProps = {
	collapseClick: boolean;
};

const MainStyle = styled("main", {
	shouldForwardProp: (property) => property !== "collapseClick",
})<MainStyleProps>(({ collapseClick, theme }) => ({
	flexGrow: 1,
	paddingTop: HEADER.MOBILE_HEIGHT + 24,
	paddingBottom: HEADER.MOBILE_HEIGHT + 24,
	[theme.breakpoints.up("lg")]: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: collapseClick ? HEADER.DASHBOARD_DESKTOP_HEIGHT + 0 : HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
		paddingBottom: collapseClick ? HEADER.DASHBOARD_DESKTOP_HEIGHT + 0 : HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
		transition: theme.transitions.create("margin-left", {
			duration: theme.transitions.duration.shorter,
		}),
		...(collapseClick && {
			marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
		}),
	},
}));

type Props = {
	children: ReactNode;
};

export default function DashboardLayout({ children }: Props) {
	const { collapseClick, isCollapse } = useCollapseDrawer();
	const { themeLayout, themeMode, themeStretch } = useSettings();
	const isDesktop = useResponsive("up", "lg");
	const [open, setOpen] = useState(false);

	const verticalLayout = themeLayout === "vertical";

	if (verticalLayout) {
		return (
			<>
				<DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

				{isDesktop ? (
					<NavbarHorizontal />
				) : (
					<NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
				)}

				<Box
					component="main"
					sx={{
						px: { lg: 2 },
						pt: {
							xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
							lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
						},
						pb: {
							xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
							lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
						},
					}}
				>
					{children}
				</Box>
			</>
		);
	}

	return (
		<Box
			sx={{
				display: { lg: "flex" },
				minHeight: { lg: 1 },
				"::before": {
					content: '""',
					background: 'url("/assets/background-image-no-line.webp")',
					filter: themeMode === "dark" ? "invert(100%) grayscale(100%)" : "none",
					backgroundRepeat: "no-repeat",
					backgroundSize: "100% 130%",
					position: "fixed",
					zIndex: -10000000,
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					opacity: 0.2,
				},
			}}
		>
			<DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
			<NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
			<MainStyle collapseClick={collapseClick}>{children}</MainStyle>
		</Box>
	);
}
