import { Locale, format, formatDistanceToNow, getTime } from "date-fns";
import { nl } from "date-fns/locale";

export function fDate(date: Date | string | number, locale: Locale = nl) {
	if (date) return format(new Date(date), "dd MMMM yyyy", { locale });
}

export function fDateNA(date: Date | string | number) {
	return format(new Date(date), "dd MMMM yyyy");
}

export function fDateShort(date: Date | string | number, locale: Locale = nl) {
	return format(new Date(date), "dd-MM-yyyy", { locale });
}

export function fDateTime(date: Date | string | number, locale: Locale = nl) {
	return format(new Date(date), "dd MMM yyyy p", { locale });
}

export function fTimestamp(date: Date | string | number) {
	return getTime(new Date(date));
}

export function fDateTimeEu(date: Date | string | number, locale: Locale = nl) {
	if (!date) return "";
	return format(new Date(date), "dd-MM-yyyy HH:mm", { locale });
}

export function fDateTimeEuSmallYear(date: Date | string | number, locale: Locale = nl) {
	return format(new Date(date), "dd-MM-yy HH:mm", { locale });
}

export function fDateTimeEuSmall(date: Date | string | number, locale: Locale = nl) {
	return format(new Date(date), "dd-MM HH:mm", { locale });
}

export function fTimeEuSmall(date: Date | string | number, locale: Locale = nl) {
	return format(new Date(date), "HH:mm", { locale });
}

export function fDateTimeSuffix(date: Date | string | number, locale: Locale = nl) {
	return format(new Date(date), "dd-MM-yyyy hh:mm p", { locale });
}

export function fToNow(date: Date | string | number, locale: Locale = nl) {
	return formatDistanceToNow(new Date(date), {
		addSuffix: true,
		locale,
	});
}

/**
 * Used to properly format times for use in the calendar
 * @param number that needs a zero in front.
 * @returns the number with a zero in front if it is smaller than 10.
 */
const addZero = (number: number) => {
	return number < 10 ? `0${number}` : number;
};

/**
 * Fullcalendar package requires recurring event start/end times
 * consisting of hours and minutes instead of a whole date.
 * @param date to extract the time from.
 * @returns a formatted string consisting of hours and minutes.
 */
export function getHoursAndMinutesFromDate(date: Date) {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	return `${String(addZero(hours))}:${String(addZero(minutes))}`;
}
