import { Drawer, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { OrganizationSelector } from "@components/clerk/OrganizationSelector";

import Logo from "../../../components/other/Logo";
import { NavSectionVertical } from "../../../components/nav-section";
import Scrollbar from "../../../components/other/Scrollbar";
import { NAVBAR } from "../../../config";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";

import CollapseButton from "./CollapseButton";
import NavbarAccount from "./NavbarAccount";
import { navConfigAdministrator, navConfigEmployee } from "./NavConfig";
import { useOrganization } from "@clerk/nextjs";

const RootStyle = styled("div")(({ theme }) => ({
	[theme.breakpoints.up("lg")]: {
		flexShrink: 0,
		transition: theme.transitions.create("width", {
			duration: theme.transitions.duration.shorter,
		}),
	},
}));

type Props = {
	isOpenSidebar: boolean;
	onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
	const theme = useTheme();
	const { membership } = useOrganization();
	const { pathname } = useRouter();

	const isDesktop = useResponsive("up", "lg");

	const { isCollapse, collapseClick, collapseHover, onToggleCollapse } = useCollapseDrawer();

	useEffect(() => {
		if (isOpenSidebar) {
			onCloseSidebar();
		}
	}, [pathname]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				"& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
			}}
		>
			<Stack
				spacing={3}
				sx={{
					pt: 3,
					pb: 2,
					px: 2.5,
					flexShrink: 0,
					...(isCollapse && { alignItems: "center" }),
				}}
			>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Stack gap={2}>
						{isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
						<Logo full={!isCollapse} />
					</Stack>

					{isDesktop && !isCollapse && (
						<CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
					)}
				</Stack>

				{!isCollapse && <NavbarAccount isCollapse={isCollapse} />}

				{!isCollapse && (
					<OrganizationSelector
						textFieldProps={{
							size: "small",
							label: "",
							fullWidth: true,
						}}
					/>
				)}
			</Stack>

			<NavSectionVertical
				navConfig={
					(membership?.publicMetadata?.hr as any)?.role === "administrator" ? navConfigAdministrator : navConfigEmployee
				}
				isCollapse={isCollapse}
			/>
		</Scrollbar>
	);

	return (
		<RootStyle
			sx={{
				width: {
					lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
				},
				...(collapseClick && {
					position: "absolute",
				}),
			}}
		>
			{!isDesktop && (
				<Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
					{renderContent}
				</Drawer>
			)}

			{isDesktop && (
				<Drawer
					open
					variant="persistent"
					PaperProps={{
						sx: {
							width: NAVBAR.DASHBOARD_WIDTH,
							borderRightStyle: "dashed",
							bgcolor: "background.default",
							transition: (theme) =>
								theme.transitions.create("width", {
									duration: theme.transitions.duration.standard,
								}),
							...(isCollapse && {
								width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
							}),
							...(collapseHover && {
								...cssStyles(theme).bgBlur(),
								boxShadow: (theme) => theme.customShadows.z24,
							}),
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</RootStyle>
	);
}
