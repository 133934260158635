import { useUser } from "@clerk/nextjs";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { setLocale } from "yup";

import { LANGUAGES, Language, defaultLang, yupValidationLanguage } from "../config";

import useSettings from "./useSettings";

// TODO: @Joey Local first, then clerk
export default function useLocales() {
	const { user } = useUser();
	const { push, asPath } = useRouter();
	const { onChangeDirectionByLang } = useSettings();
	const { i18n } = useTranslation();
	const langStorage = useMemo(() => user?.unsafeMetadata.lang || defaultLang.value, [user]);
	const currentLang = useMemo(
		() => LANGUAGES.find((language) => language.value === i18n.language) ?? LANGUAGES[0],
		[langStorage]
	);

	useEffect(() => {
		if (typeof window !== "object") return;
		const langStorageParsed = LANGUAGES.find((language) => language.value === langStorage) ?? LANGUAGES[0];
		push(asPath, asPath, { locale: langStorageParsed.path });
	}, [langStorage]);

	const handleChangeLanguage = (newLang: Language) => {
		if (newLang !== currentLang) {
			onChangeDirectionByLang(newLang.value);
			user?.update({
				unsafeMetadata: {
					...user.unsafeMetadata,
					lang: newLang.value,
				},
			});
		}
	};

	useEffect(() => {
		setLocale(yupValidationLanguage);
	}, [currentLang]);

	return {
		onChangeLang: handleChangeLanguage,
		currentLang,
		allLangs: LANGUAGES,
	};
}
