import { UserButton, OrganizationSwitcher } from "@clerk/nextjs";
import { AppBar, Box, Stack, Toolbar, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import SettingsButton from "@layouts/dashboard/header/SettingsButton";

import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/other/Iconify";
import Logo from "../../../components/other/Logo";
import { HEADER, NAVBAR } from "../../../config";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";

import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import Searchbar from "./Searchbar";
import useSettings from "@hooks/useSettings";

type RootStyleProps = {
	isCollapse: boolean;
	isOffset: boolean;
	verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
	shouldForwardProp: (property) =>
		property !== "isCollapse" && property !== "isOffset" && property !== "verticalLayout",
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
	...cssStyles(theme).bgBlur(),
	boxShadow: "none",
	height: HEADER.MOBILE_HEIGHT,
	zIndex: theme.zIndex.appBar + 1,
	transition: theme.transitions.create(["width", "height"], {
		duration: theme.transitions.duration.shorter,
	}),
	[theme.breakpoints.up("lg")]: {
		height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
		...(isCollapse && {
			width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
		}),
		...(isOffset && {
			height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
		}),
		...(verticalLayout && {
			width: "100%",
			height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
			backgroundColor: theme.palette.background.default,
		}),
	},
}));

type Props = {
	onOpenSidebar: VoidFunction;
	isCollapse?: boolean;
	verticalLayout?: boolean;
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }: Props) {
	const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
	const { themeStretch } = useSettings();
	const isDesktop = useResponsive("up", "lg");

	return (
		<RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
			<Toolbar
				sx={{
					minHeight: themeStretch ? null : "100% !important",
					px: { lg: 5 },
				}}
			>
				{isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

				{!isDesktop && (
					<IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
						<Iconify icon="eva:menu-2-fill" />
					</IconButtonAnimate>
				)}

				<Searchbar />
				<Box sx={{ flexGrow: 1 }} />

				<Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
					<OrganizationSwitcher />
					<NotificationsPopover />
					<LanguagePopover />
					<SettingsButton />
					<Tooltip arrow title="Account">
						<span>
							<UserButton />
						</span>
					</Tooltip>
				</Stack>
			</Toolbar>
		</RootStyle>
	);
}
