import { useOrganization, useOrganizationList } from "@clerk/nextjs";
import OfflineBolt from "@mui/icons-material/OfflineBolt";
import { MenuItem, Stack, TextField, TextFieldProps } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useCallback, useState } from "react";

type OrganizationSelectorProps = {
	onSelect?: (organization: string) => void;
	textFieldProps?: TextFieldProps;
};

export const OrganizationSelector = ({ onSelect, textFieldProps }: OrganizationSelectorProps) => {
	const { setActive, organizationList, isLoaded } = useOrganizationList();
	const { organization } = useOrganization();
	const [activeOrg, setActiveOrg] = useState<string>(organization?.id || "");

	const [offlineHosts, setOfflineHosts] = useState<string[]>([]);
	const [initialized, setInitialized] = useState(false);

	// useEffect(() => {
	// 	const controller = new AbortController();
	// 	const { signal } = controller;

	// 	const pingBackend = async (slugs: string[]) => {
	// 		setInitialized(false);
	// 		// const offline = [];
	// 		// for (const slug of slugs) {
	// 			// eslint-disable-next-line no-await-in-loop -- The eslint proposed await Promise.all() fix is not compatible if we need to check res.ok status within for loop aswell.
	// 			// const response = await fetch(`https://${slug}.hasura.app/healthz`, { signal }); // TODO: Reenable the lines once we have a Hasura/Clerk app for each organization.
	// 			// if (!response.ok) {
	// 			// 	offline.push(slug);
	// 			// }
	// 		// }
	// 		// setOfflineHosts(offline);
	// 		setInitialized(true);
	// 	};;
	// 	if (isLoaded) {
	// 		const hosts = organizationList.map((org) => org.organization.slug);
	// 		pingBackend(hosts);
	// 	}
	// 	return () => controller.abort();
	// }, []);

	const isOffline = useCallback((slug?: string | null) => (slug ? offlineHosts.includes(slug) : true), [offlineHosts]);
	const getSelectedOrganization = useCallback(
		(id?: string) => {
			if (id && organizationList?.length) {
				return organizationList.find((org) => org.organization.id === id)?.organization;
			}
		},
		[organizationList]
	);

	if (!isLoaded) {
		return null;
	}

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const organization_id = event.target.value;
		if (!onSelect) {
			setActive({ organization: organization_id });
		} else {
			onSelect(organization_id);
		}
		setActiveOrg(organization_id);
	};

	const sortedOrgList = organizationList.sort((a, b) => (isOffline(a.organization.slug) ? 1 : -1));

	return (
		<TextField
			label="Organizatie"
			{...textFieldProps}
			disabled={!initialized}
			SelectProps={{
				renderValue: (value) => (
					<Stack direction="row" gap={1}>
						<ListItemIcon sx={{ mr: 0 }}>
							<OfflineBolt
								color={isOffline(getSelectedOrganization(value as string)?.slug ?? "") ? "error" : "success"}
							/>
						</ListItemIcon>
						<span>{getSelectedOrganization(value as string)?.name}</span>
					</Stack>
				),
			}}
			select
			value={activeOrg ?? organization?.id}
			onChange={handleOrganizationChange}
		>
			{sortedOrgList
				.sort((a, b) => (isOffline(a.organization.slug) ? 1 : -1))
				.map(({ organization }) => (
					<MenuItem key={organization.id} disabled={isOffline(organization.slug)} value={organization.id}>
						<ListItemIcon sx={{ mr: 0 }}>
							<OfflineBolt color={isOffline(organization.slug) ? "error" : "success"} />
						</ListItemIcon>
						<span>{organization.name}</span>
					</MenuItem>
				))}
		</TextField>
	);
};
