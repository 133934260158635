import { Link } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { getActive, isExternalLink } from "..";
import { NavListProps } from "../type";

import NavItem from "./NavItem";
import { PaperStyle } from "./style";

type NavListRootProps = {
	data: NavListProps;
	depth: number;
	hasChildren: boolean;
};

export default function NavList({ data, depth, hasChildren }: NavListRootProps) {
	const menuReference = useRef(null);

	const { pathname, asPath, push } = useRouter();

	const active = getActive(data.path, pathname, asPath);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (open) {
			handleClose();
		}
	}, [pathname]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickItem = () => {
		if (!hasChildren) {
			push(data.path);
		}
	};

	return (
		<>
			{isExternalLink(data.path) ? (
				<Link href={data.path} target="_blank" rel="noopener" underline="none">
					<NavItem item={data} depth={depth} open={open} active={active} />
				</Link>
			) : (
				<NavItem
					item={data}
					depth={depth}
					open={open}
					active={active}
					ref={menuReference}
					onClick={handleClickItem}
					onMouseEnter={handleOpen}
					onMouseLeave={handleClose}
				/>
			)}

			{hasChildren && (
				<PaperStyle
					open={open}
					anchorEl={menuReference.current}
					anchorOrigin={
						depth === 1 ? { vertical: "bottom", horizontal: "left" } : { vertical: "center", horizontal: "right" }
					}
					transformOrigin={
						depth === 1 ? { vertical: "top", horizontal: "left" } : { vertical: "center", horizontal: "left" }
					}
					PaperProps={{
						onMouseEnter: handleOpen,
						onMouseLeave: handleClose,
					}}
				>
					<NavSubList data={data.children} depth={depth} />
				</PaperStyle>
			)}
		</>
	);
}

type NavListSubProps = {
	data: NavListProps[];
	depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
	return (
		<>
			{data.map((list) => (
				<NavList key={list.title + list.path} data={list} depth={depth + 1} hasChildren={!!list.children} />
			))}
		</>
	);
}
